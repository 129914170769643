import React from 'react';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// Carousel
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';


// Redux =>
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

// Routing =>
import { Link, Redirect } from 'react-router-dom'

// Actions
import {eventInfo, getAdditionals, objAdditionals} from './Actions';
import {isOut} from '../Utilities/Actions';

// Swiper
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
//import "swiper/css";

// Icons
import { GiLindenLeaf, GiPartyPopper, GiGreekTemple, GiHiking } from 'react-icons/gi';
import { BsChevronCompactUp, BsShieldCheck } from 'react-icons/bs';
import { FiShare } from 'react-icons/fi';
import { BiCheck  } from 'react-icons/bi';

// With Styles =>
const styles = theme => {
    return {
        progress : {
            color: '#00a550',
            marginTop: '220px'
        },
        centered : {
            textAlign: 'center'
        }
    };
};



/****************************************
    Return Render =>
****************************************/
class Detalle extends React.Component {
    // Obtiene la información del evento por su slug =>
    componentDidMount(){
        let segments = window.location.pathname.split('/');
        let slug = segments[(segments.length - 1)];
        this.props.eventInfo(slug).then((info) =>{

            // SEO
            document.title = info.name+' | Mar-Iguana Tours';
            document.querySelector("meta[name='description']").content="Ve preparando tus maletas para salir de aventura con nosotros, atrévete a vivir la experiencia en estos próximos viajes que estamos preparando para ti. ";
            document.querySelector("meta[name='keywords']").content="viajes, viajeros, ecoturismo, méxico, agencia, aventura, vacaciones, viajes en grupo, viajes economicos, destinos , naturaleza, huasteca, cuba, sustentabilidad, experiencias , diversión, convivencia, próximas salidas, puentes, playas";
        });

        document.loop = 0;
        document.bgLoop = setInterval(() => {
            var banners = document.getElementsByClassName("banner-item");
            for (let item of banners) {
                item.style["background-image"] = 'url("'+item.title+'")';
            }
            document.loop++;
            if(document.loop === 15){
                clearInterval(document.bgLoop);
            }
        }, 500);

        window.addEventListener('scroll', this.handleScroll);
    }


    handleScroll(){
        let element = document.getElementsByClassName('back-to-booking')[0];
        let whatsAppBtn = document.getElementsByClassName('whatsapp-plugin')[0];
        if(element){
            let className = (window.pageYOffset > 600) ? 'back-to-booking visible' : 'back-to-booking ';
            let whatsAppClass = (window.pageYOffset > 600) ? 'whatsapp-plugin higher' : 'whatsapp-plugin ';
            element.setAttribute('class', className);

            if(whatsAppBtn && whatsAppClass){
                whatsAppBtn.setAttribute('class', whatsAppClass);
            }
        }
    }


    componentDidUpdate(prevProps) {
        var prev = (prevProps.agenda.event) ? prevProps.agenda.event.slug : false;
        let segments = window.location.pathname.split('/');
        let slug = segments[(segments.length - 1)];
        if(prev && prev !== slug)
        {
            this.props.eventInfo(slug);
        }
    }

    // Acción y botón de compra:
    btnBuy(){
        if(this.props.global.authorized){
            document.location.href = "/perfil/reportar-abono/"+this.props.agenda.event.slug;
        } else {
            this.props.isOut(true);
            localStorage.setItem("intendedUrl", "/perfil/reportar-abono/"+this.props.agenda.event.slug);
        }
    }


    // Check paquete y obtiene adicionales
    packToggle(id){
        this.props.getAdditionals(id);
    }


    // Check/Uncheck adicionales =>
    addToggle(id){
        this.props.objAdditionals(id);
    }


    // Componente para Paquetes =>
    cPack(pack){
        let total = this.props.agenda.total;
        let current = this.props.agenda.selectedPackage;
        let style  = (current === pack.id) ? 'pack active' : 'pack';
        return (
            <button className={style} key={'pack-'+pack.id}  onClick={() => (current === pack.id) ? this.btnBuy() : this.packToggle(pack.id)}>
                <h2>AHORRA HOY</h2>
                <div className='texts'>
                    <span>Precio normal</span>
                    <div className='old-price'>{this.getContentInHTML('PRECIO NORMAL')}</div>
                    <big>${total ? total : pack.price}</big>
                    <p>{pack.name}</p>
                    <i>{pack.description}</i>
                    <div className={'button'}>{ (current !== pack.id) ? 'Elegir paquete' : '¡Reservar ahora!'}</div>
                </div>
            </button>
        )
    }


    // Componente para Adicionales =>
    cAdd(additional){
        let current = this.props.agenda.selectedAdditionals;
        //let btn  = (Object.values(current).indexOf(additional.id) !== -1) ? <button type="button" className={"cta green"} onClick={() => this.addToggle(additional.id)}><i className="fas fa-check"></i> Seleccionado</button> : <button type="button" className={"cta gray"} onClick={() => this.addToggle(additional.id)}>Elegir adicional</button>;
        let selected = (Object.values(current).indexOf(additional.id) !== -1) ? 'box selected' : 'box';
        return (
            <article  className={selected} key={"add-"+additional.id } onClick={() => this.addToggle(additional.id)}>
                <big>${additional.price}</big>
                <b>+ { additional.name }</b>
            </article>
        )
    }


    // Si no hay adicionales, muestra mensaje
    hasAdds(){
        return (this.props.agenda.additionals.length > 0 ) ? <h2>Adicionales</h2> : <em style={{textAlign:"center", display: "block", color: "#bcbcbc"}}>Sin adicionales</em>;
    }

    getContentInHTML(keyword){
        let wysiwyg = this.props.agenda.event.wysiwyg;
        let HR = '***HR***';
        let NL = '\n';
        // Replace <hr> by HR
        let clean = wysiwyg.replace(/(\<hr\/>|<hr>)/gmi, HR);

        // Replace <br> by NL
        clean = clean.replace(/(\<br\>)/gmi, NL);

        // Replace </p> ending by NL
        if(keyword === 'DETALLES')
        clean = clean.replace(/(\<\/p\>)/gmi, 'BRINP');

        // Replace <br> by NL
        clean = clean.replace(/(\<br\>)/gmi, NL);

        // Replace &nbsp;
        clean = clean.replace(/(&nbsp;)/g, ' ');

        // Remove html tags
        clean = clean.replace(/(<([^>]+)>)/gmi, '');

        // Set bold words
        if(keyword === 'DETALLES'){
            clean = clean.replace(/\##.*?\##/gmi, function(match) {
                let bold = "<h3>" + match + "</h3>";
                return bold.replace(/\#/gmi, '');
            });
        }

        // Split clean string by the $keyword:
        clean = clean.split(keyword);

        // IF keyword was found, continue
        if(clean[1]){
            clean = clean[1].split(HR)[0];
        } else {
            // Just retun empty
            clean = '';
        }


        if(keyword === 'VISITAREMOS'){
            /*
            clean = clean.replace(/\#.*?\#/gmi, function(match) {
                let h3 = "<h3>" + match + "</h3>";
                return h3.replace(/\#/gmi, '');
            });
            */
            //console.log(clean);
        }
        return clean;
    }


    backToBooking(){
        let {selectedPackage, event, total } = this.props.agenda;
        let currentPack = (selectedPackage) ? event.packages.filter((a) => a.id === selectedPackage)[0] : event.packages[0];
        return (
            <div className={'back-to-booking'}>
                <h2>{currentPack.name}<br/>{currentPack.description}</h2>
                <big>${total ? total : currentPack.price}</big>
                <button onClick={() => window.scroll({top: (document.getElementsByClassName('description-and-hook')[0].offsetTop - 50), behavior: "smooth"})}>Reservar <BsChevronCompactUp size={25}/></button>
            </div>
        )
    }


    loadCarousel(){
        let event = this.props.agenda.event;
        return (
            <Carousel className="banners-holder" transitionTime={1000} emulateTouch={true} autoPlay={true} interval={3000} showThumbs={false} infiniteLoop={true} showStatus={false} showIndicators={true}>
                {event.banners.map((banner, i) =>
                    <div className="banner-item" key={"banner-"+banner.id} style={{backgroundImage: 'url('+banner.path+') !important'}} title={banner.path}>
                    </div>
                )}
            </Carousel>
        )
    }

    // Render =>
    render() {
        const { classes } = this.props;
        const { event, events, additionals, selectedPackage }       = this.props.agenda;
        const ww          = this.props.global.windowwidth;

        const icons = {
            'Ecoturismo' : <GiLindenLeaf size={30} color={'#fff'}/>,
            'Fiesta' : <GiPartyPopper size={30} color={'#fff'}/>,
            'Arte y Cultura' : <GiGreekTemple size={30} color={'#fff'}/>,
            'Condición Física' : <GiHiking size={30} color={'#fff'}/>
        };

        return (
            event ?
                <div id='event-detail'>
                    <section>
                        <a href={`whatsapp://send?text=https://mar-iguanatours.com.mx/agenda/${event.slug}`} data-action="share/whatsapp/share" className='titles'>
                            <h1>{event.name}</h1>
                            <h2>{ event.period }</h2>
                            <FiShare size={20}/>
                        </a>
                    </section>
                    {this.loadCarousel()}
                    {/*<div className='hero' style={{backgroundImage: 'url("'+event.banners[0].path+'")'}}></div>*/}

                    <section>
                        <div className='description-and-hook'>
                            <div className='text-area'>
                                {/*<h2>Viaja { event.period }</h2>*/}
                                {/*<h3>Detalles</h3>*/}
                                <p dangerouslySetInnerHTML={{__html: this.getContentInHTML('DETALLES').replace(/(BRINP)/gmi, '<br/><br/>') }}></p>
                            </div>
                            <div className='booking-area'>
                                {event.available > 10 ?
                                    <div className='box'>
                                        <h2>¡RESERVA YA!</h2>
                                        <p style={{'color': '#a19d00' }}>POCOS LUGARES DISPONIBLES</p>
                                    </div>
                                :
                                    <div className='box'>
                                        <h2>ÚLTIMOS LUGARES</h2>
                                        <p style={{ color: 'red'}}>QUEDAN SÓLO {event.available} LUGARES</p>
                                    </div>
                                }
                                {/* this.cPack(pack) */}
                                {event.packages.map((pack) =>
                                    this.cPack(pack)
                                )}
                                <div className='additionals-holder'>
                                    {additionals ? (
                                        <React.Fragment>
                                            {( this.hasAdds() )}
                                            {additionals.map((additional) =>
                                                this.cAdd(additional)
                                            )}
                                        </React.Fragment>
                                    ) : (
                                        <div className={classes.centered}>
                                            {this.props.agenda.loadingads ? (
                                                <CircularProgress style={{ color: '#f0ea03', }} />
                                            ) : (
                                                <em style={{ display:'block', fontSize:'11px', textAlign:'center', color: '#bcbcbc' }}>Selecciona un paquete para mostrar sus adicionales</em>
                                            )}
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </section>

                    <div className='hero-amenities' style={{ backgroundImage: `url("${event.banners[0].path}")`}}>
                        {this.getContentInHTML('RECOMENDACIONES').split("\n").map((item, idx) => {
                            if (item && item.length > 5){
                                let parts = item.split(':');
                                let icon = (icons[parts[0]]) ? icons[parts[0]] : <BsShieldCheck size={30} color={'#fff'}/>
                                return (
                                    <section key={'amenities-'+idx}>
                                        <div className='icon-holder'>{icon}</div>
                                        <span>{parts[0]}</span>
                                        <ul>
                                            {Array(5).fill('').map((k, idx) => {
                                                return (
                                                    <li key={'bullet-'+idx} className={((idx + 1) <= parseInt(parts[1])) ? 'on' : ''}></li>
                                                )
                                            })}
                                        </ul>
                                    </section>
                                )
                            } else {
                                return null;
                            }
                        })}
                    </div>

                    <section>
                        <div className='to-visit box'>
                            <h2>Visitaremos</h2>
                            {this.getContentInHTML('VISITAREMOS').split("\n").map((row, idx) => {
                                if(row.search('#') !== -1){
                                    let blockTitle = row.replace(/\#.*?\#/gmi, function(match) {
                                        let str = match.replace(/\#/gmi, '');
                                        return str;
                                    });
                                    return (blockTitle && blockTitle.length > 5) ? <h3 key={'lit-'+idx}>{blockTitle}</h3> : null;
                                } else {
                                    return (row && row.length > 5) ? <div className='place-row' key={'liv-'+idx}>{row.replace('•','')}</div> : null
                                }
                            })}
                            {/*
                            <ul>
                                {this.getContentInHTML('VISITAREMOS').split("\n").map((li, idx) => {
                                    if(li.search('#') !== -1){
                                        let liTitle = li.replace(/\#.*?\#/gmi, function(match) {
                                            let str = match.replace(/\#/gmi, '');
                                            return str;
                                        });
                                        return <li className='li-title'>{liTitle}</li>;
                                    } else {
                                        return (li && li.length > 5) ? <li key={'liv-'+idx}>{li.replace('•','')}</li> : null
                                    }
                                })}
                            </ul>
                            */}
                        </div>

                        <div className='includes'>
                            <h2>Incluye</h2>
                            <ul>
                                {this.getContentInHTML('INCLUYE').split("\n").map((li, idx) => {
                                    return (li && li.length > 5) ? <li key={'lii-'+idx}>{li.replace('•','')}</li> : null
                                })}
                            </ul>
                        </div>
                    </section>


                    {this.loadCarousel()}

                    <section>
                        <div className='booking-how'>
                            <h2>¿Cómo reservar?</h2>
                            <div className='boxes'>
                                <div className='box'>
                                    <h3>OPCIÓN 1</h3>
                                    <h4>Reservaciones directas en la oficina:</h4>
                                    <p>
                                        Horario de Atención:<br/>
                                        De lunes a viernes de 12 a 8 pm. Sábados de 10 a 2 pm.<br/>
                                        Dirección oficinas:<br/>
                                        Garibaldi #753 esquina con calle Puebla, Zona centro.<br/>
                                        Teléfono: <a href="tel:3320159141">33 2015 9141</a><br/>
                                        Cel/WhatsApp: <a href="https://wa.me/5213319077565">33 1907 7565</a><br/>
                                        Estos sólo de 12 pm a 8 pm
                                    </p>
                                </div>
                                <div className='box'>
                                    <h3>OPCIÓN 2</h3>
                                    <h4>Depósito / transferencia:</h4>
                                    <p>
                                        Banco Santander Cta.: 60556378138<br/><br/>
                                        Clabe Interbancaria.: 014320605563781380<br/><br/>
                                        Tarjeta para depósito en OXXO:<br/>
                                        5579 0701 6036 9941
                                    </p>
                                    <br/>
                                    <span>Todo a nombre de Miguel Mitl González Gómez</span>
                                </div>
                            </div>
                            <legend><b>Nota:</b> Si realizas depósito o transferencia necesitas registrarte en nuestra página web <a>www.mar-iguanatours.com.mx</a> y ahí sube la foto de tu pago. Para registrarte necesitas los siguientes datos: Nombre, teléfono, correo electrónico y talla de playera. (Recuerda que es un registro por persona).</legend>
                        </div>


                        <div className='swiper-events-holder'>
                            <h2>Conoce nuestros viajes disponibles</h2>
                            <Swiper
                                className='product-related-swiper'
                                spaceBetween={0}
                                slidesPerView={'auto'}
                                modules={[Navigation]}
                                navigation={{ nextEl: "#swiper-next", prevEl: "#swiper-back" }}
                            >
                                {events ?
                                    events.map((item, idx) => {
                                        return (
                                            <SwiperSlide className={'slide-item'} key={'slider-'+idx}>
                                                <div className='box'>
                                                    <div className='img-holder' style={{ backgroundImage: `url("${item.img}")`}}></div>
                                                    <div className='texts'>
                                                        <b>{item.name}</b>
                                                        <span>{item.packages[0].description}</span>
                                                        <big>{item.packages[0].price}</big>
                                                        <Link to={`/agenda/${item.slug}`}>Detalles del viaje</Link>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })
                                : null }
                            </Swiper>
                        </div>
                    </section>
                    { this.backToBooking()}
                </div>
            :
                <Grid container justify="center">
                    <Grid item xs={2} align="center">
                        <CircularProgress className={classes.progress}/>
                    </Grid>
                </Grid>
        )
    }
}

/********************************************
    State  to Props =>
********************************************/
function mapStateToProps(state){
    return state;
}



/********************************************
    Bind actions to be uses along redux =>
********************************************/
function matchDispatchToProps(dispatch){
    return bindActionCreators({
        eventInfo,
        getAdditionals,
        objAdditionals,
        isOut
    }, dispatch);
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, matchDispatchToProps)
)(Detalle);